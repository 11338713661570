/* App.css */

.App {
  text-align: center;
  font-family: Arial, sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-header {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

h1 {
  font-size: 3em;
  margin-bottom: 0.5em;
}

p {
  font-size: 1.2em;
}

.App-footer {
  background-color: #f1f1f1;
  padding: 1em 0;
}

.App-footer p {
  margin: 0.2em 0;
}
